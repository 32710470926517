import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "progaming-co-ltd"
    }}>{`ProGaming Co. Ltd.`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "เกี่ยวกับเรา"
    }}>{`เกี่ยวกับเรา`}</h3>
    <p>{`ทางบริษัทฯ มีจุดมุ่งหมายที่จะเป็นผู้นำผู้ให้บริการทางด้านไอที และเป็นผู้นำด้านการให้ความรู้แก่นักพัฒนามือใหม่
เพื่อให้ก้าวขึ้นมาเป็นนักพัฒนามืออาชีพได้อย่างเต็มตัวด้วยความสอดคล้องและเอื้ออำนวยกันของวัตถุประสงค์ทั้งสอง`}</p>
    <p>{`บริษัท โปรเกมมิ่ง จำกัด หวังเป็นอย่างยิ่งที่จะสร้างชุมชนของนักพัฒนาขึ้นมา การให้ความรู้จะสร้างนักพัฒนาป้อนให้กับอุตสาหกรรมซอฟท์แวร์ เมื่อคุณภาพของนักพัฒนาของไทยมีคุณภาพสูงขึ้น
ย่อมทำให้คุณภาพซอฟท์แวร์ของไทยสูงขึ้น และสามารถแข่งขันกับอุตสาหกรรมต่างชาติได้ในไม่ช้า`}</p>
    <p><img alt="team" src={require("./public/images/progaming.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      